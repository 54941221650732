<template>
  <div>
    <v-toolbar dark dense :color="$store.getters.getColor('primary')" fixed>
      <v-btn icon @click="$router.go(-1);">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="espacamento-linhas pt-1">
        <span>Simulações</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="d-flex justify-center mt-10 pa-10" v-if="loading.page">
      <v-progress-circular indeterminate color="grey" size="50"></v-progress-circular>
    </div>
    <div v-else>
    <v-container class="pa-5 pb-5 pt-0">
      <v-expansion-panels multiple class="pb-5 pt-5 elevation-0" flat v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-content active>
            <div class="row">
              <div class="col-12">
                <strong>Nome</strong><br/>
                {{ item.nome }}
              </div>
              <div class="col-12">
                <strong>E-mail</strong><br/>
                {{ item.email }}
              </div>
              <div class="col-12">
                <strong>Telefone</strong><br/>
              {{ formataTelefone(item.telefone) }}
              </div>
            </div>
            <v-row>
              <v-col cols="12" md="6" xl="4" v-for="(dado, index) in item.simulacoes" :key="`item-${index}`">
              <base-card-planos-simulacao
                class="cursor-pointer"
                :plano="dado.plano.nomePlanoAns"
                :nascimento="dado.titular.dataNascimento"
                :profissao="dado.titular.publicoAlvo"
                :entidade="dado.plano.entidade"
                :cidade="dado.endereco.cidade"
                :valor="dado.plano.valorTotal"
                :estado="dado.endereco.estado"
                :dataCriacao="dado.dataCriacao"
                :grupo="dado"
                @planodetalhes="verDetalhe(dado.guid)"
                @selecionarsimulacao="selecionarSimulacoes($event)"
              />
              </v-col>
          </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dependentes
            </h1>
          </v-expansion-panel-header>
          <v-divider class="mb-4"></v-divider>
          <v-expansion-panel-content active>
            <v-row v-if="dados.dependentes.length > 0" class="ml-2">
              <v-col class="col-12" v-for="(dependente, index) in dados.dependentes" :key="index">
                {{ formataNascimento(dependente.dataNascimento) }}
              </v-col>
            </v-row>
            <v-row v-else class="ml-2">
              <v-col class="col-12">
                Não há dependentes
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
      </v-expansion-panels>
    <v-btn
      v-if="showButton"
      color="primary"
      fixed
      dark
      right
      bottom
      fab
      class="mt-10"
      style="bottom: 30px"
      @click="dialogEnvio = true"
    >
      <v-icon>mdi-send</v-icon>
    </v-btn>
        <v-bottom-sheet
        v-model="dialogEnvio"
        inset
      >
        <v-sheet>
          <div class="my-3">
            <v-list>
              <v-list-item @click="enviaSimulacao('whatsapp')">
                <v-list-item-avatar>
                  <v-avatar tile>
                    <v-icon large class="green--text">mdi-whatsapp</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>
                  WhatsApp
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="enviaSimulacao('email')">
                <v-list-item-avatar>
                  <v-avatar tile>
                    <v-icon large class="primary--text">mdi-email</v-icon>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-title>
                  Email
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </v-container>
    </div>
  </div>
</template>
<script>
import simulacaoService from '@/services/simulacaoService';
import BaseCardPlanosSimulacao from '@/components/simulacoes/BaseCardPlanosSimulacao.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'simulacao-cliente',
  components: {
    BaseCardPlanosSimulacao,
  },
  data() {
    return {
      newForm: [],
      dialogEnvio: false,
      item: this.$route.params.item,
      dados: {},
      panel: [0, 1, 2],
      loading: {
        page: false,
        editar: false,
        excluir: false,
      },
    };
  },
  computed: {
    showButton() {
      return this.newForm.length > 0;
    },
  },
  methods: {
    ...mapActions({
      setTitular: 'simulacao/setTitular',
      setEndereco: 'simulacao/setEndereco',
      setAnotacao: 'simulacao/setAnotacao',
      setPlano: 'simulacao/setPlano',
      setEditando: 'simulacao/setEditando',
      setDependentes: 'simulacao/setDependentes',
    }),
    formataNascimento(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataTelefone(tel) {
      if (tel && tel !== "undefined") {
        return tel.length === 11 ? `${tel.substring(0, 2)} ${tel.substring(2, 7)}-${tel.substring(7, 11)}` : `${tel.substring(0, 2)} ${tel.substring(2, 6)}-${tel.substring(6, 10)}`;
      }
      return '--';
    },
    verDetalhe(guid) {
      this.$router.push({ name: 'areaLogada.simulacaoResumo', params: { guid } });
    },
    async enviaSimulacao(tipoEnvio) {
      this.dialogEnvio = false;
      try {
        const dados = {
          tipoEnvio,
          simulacoes: this.newForm,
          titular: {
            nome: this.item.nome,
            email: this.item.email,
            telefone: {
              numero: this.item.telefone,
              tipo: this.item.tipoTelefone,
              preferencial: true,
            },
          },
        };
        if (dados.simulacoes.length > 3) {
          this.$root.$snackBar.open({
            color: 'error',
            message: 'Selecione até três simulações para envio.',
          });
          return;
        }
        const data = await simulacaoService.enviarSimulacao({ dados });
        if (tipoEnvio === 'email') {
          this.$root.$snackBar.open({
            color: 'success',
            message: 'Email Enviado com sucesso!',
          });
        }
        if (tipoEnvio === 'whatsapp') {
          window.open(`https://api.whatsapp.com/send?phone=+55${this.item.telefone}&text=Olá ${this.item.nome}, para visualizar as simulações basta clicar neste link: ${data.data.link}`);
        }
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
      }
    },
    selecionarSimulacoes(data) {
      const simulacao = this.newForm.findIndex((item) => item.guid === data);
      if (simulacao === -1) {
        this.newForm.push({ guid: data });
        return;
      }
      this.newForm.splice(simulacao, 1);
    },

    // editar() {
    //   this.loading.editar = true;
    //   const {
    //     titular, anotacao, endereco, dependentes, guid,
    //   } = this.dados;
    //   this.setGuid({ guid });
    //   this.setTitular({ titular });
    //   this.setAnotacao({ anotacao });
    //   this.setEndereco({ endereco });
    //   this.setDependentes({ dependentes });
    //   this.setEditando({ editando: true });
    //   this.$router.push({ name: 'areaLogada.criarSimulacao' });
    //   this.loading.editar = false;
    // },
  },
};

</script>
<style lang="scss" scoped>
.break-text {
  word-break: break-all;
}
</style>
