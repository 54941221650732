<template>
  <v-card style="padding: 1rem" class="mb-2">
    <div class="d-flex d-sm-none" style="justify-content: center;">
      <v-card style="height: 60px; width: 70px; justify-content: center;" class="d-flex flex-wrap">
        <span
          style="
                  font-size: 13px;
                  background-color: red;
                  color: white;
                  text-align: center;
                  height: 30%;
                  width: 100%;
                  padding: 3px 5px 0px 5px;
                "
          >{{ mes }}/{{ ano }}</span
        >
        <span
          style="
                  text-align: center;
                  height: 15%;
                  width: 100%;
                  color: black;
                  font-size: 30px;
                  padding-bottom: 25px;
                "
          >{{ dia }}</span
        >
      </v-card>
    </div>
    <v-checkbox @click="$emit('selecionarsimulacao', grupo.guid)" :value="grupo">
      <div slot="label">
        <div class="d-flex">
          <v-card style="height: 60px" class="d-none d-sm-flex flex-wrap">
            <span
              style="
                font-size: 13px;
                background-color: red;
                color: white;
                text-align: center;
                height: 30%;
                width: 100%;
                padding: 0px 5px 0px 5px;
              "
              >{{mes}}/{{(ano)}}</span
            >
            <span
              style="
                text-align: center;
                height: 15%;
                width: 100%;
                color: black;
                font-size: 30px;
              "
              >{{dia}}</span
            >
          </v-card>
          <div style="width: 100% !important" class="ml-6">
            <div class="d-flex">
              <h3 class="subtitle-1">{{ plano }}</h3>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <h3 class="font-weight-bold mb-1 subtitle-2">
                  Data Nascimento
                </h3>
                <span>{{ formataNascimento(nascimento) }}</span>
              </div>
              <div>
                <v-chip color="primary" class="ma-2" dark label>
                  <span class="mt-1 text-uppercase">{{
                    valor | currency
                  }}</span>
                </v-chip>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h3 class="font-weight-bold mb-1 subtitle-2">Profissão</h3>
                <span>{{ profissao }}</span>
              </div>
              <div class="ml-6">
                <h3 class="font-weight-bold mb-1 subtitle-2">Entidade</h3>
                <span>{{ entidade }}</span>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <h3 class="font-weight-bold mb-1 subtitle-2">Cidade</h3>
                <span>{{ cidade }}</span>
              </div>
              <div class="ml-6">
                <h3 class="font-weight-bold mb-1 subtitle-2">Estado</h3>
                <span>{{ estado }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-checkbox>
    <v-col cols="12" class="pa-0">
      <v-btn text block class="text-capitalize" @click="$emit('planodetalhes')">
        <span class="mt-1 subtitle-2">Clique para ver mais</span>
        <v-icon right>mdi-eye-outline</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script>
import moment from "moment";

moment.locale('pt');

export default {
  data() {
    return {
      dia: '',
      mes: '',
      ano: '',
    };
  },
  props: {
    grupo: {
      type: Object,
    },
    dataCriacao: {
      type: String,
    },
    entidade: {
      type: String,
      default: "--",
    },
    profissao: {
      type: String,
      default: "--",
    },
    estado: {
      type: String,
      default: "--",
    },
    cidade: {
      type: String,
      default: "--",
    },
    plano: {
      type: String,
      default: "--",
    },
    data: {
      type: String,
    },
    nascimento: {
      type: String,
    },
    valor: {
      type: String,
      default: "0",
    },
  },
  created() {
    this.formartCriacao(this.dataCriacao);
  },
  methods: {
    formataNascimento(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    formataData(data) {
      return moment(data).format("DD/MM/YYYY hh:mm:ss");
    },
    formartCriacao(data) {
      const check = moment(data, 'YYYY/MM/DD');

      this.mes = check.format('MMM');
      this.dia = check.format('D');
      this.ano = check.format('YYYY');
    },
  },
};
</script>

<style>
</style>
