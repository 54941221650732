import api from '@/services/api';
import AppError from '@/utils/appError';
import filters from '@/filters';

const resource = 'simulacao';
const getSimulacoes = async ({ filtros = null }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_SIMULACAO_API_KEY } };
    let { data } = await api.get(`${resource}`, config);
    data = data.data;
    if (filtros.nome) {
      data.forEach((d) => {
      });
      data = data.filter((d) => (d.titular.nome.toLowerCase()).indexOf(filtros.nome.toLowerCase()) > -1);
    }
    if (filtros.dataInicio) {
      let dataInicio = filters.convertDateInput(filtros.dataInicio);
      let dataFim = filters.convertDateInput(filtros.dataFim);
      dataInicio = (new Date(`${dataInicio} 00:00:00`).getTime());
      dataFim = (new Date(`${dataFim} 23:59:59`).getTime());
      data = data.filter((d) => (new Date(d.dataCriacao).getTime() >= dataInicio && new Date(d.dataCriacao).getTime() <= dataFim));
    }
    if (filtros.orderBy === 'nome') {
      return data.sort((a, b) => a.titular.nome.localeCompare(b.titular.nome));
    }
    return data.sort((a, b) => new Date(b.dataCriacao) - new Date(a.dataCriacao));
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const getSimulacao = async ({ guid }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_SIMULACAO_API_KEY } };
    const { data } = await api.get(`${resource}/${guid}`, config);
    return data.data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const add = async ({ dados }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_SIMULACAO_API_KEY } };
    const { data } = await api.post(`${resource}`, dados, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const remover = async ({ guid }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_SIMULACAO_API_KEY }, data: { guid } };
    const { data } = await api.delete(`${resource}`, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

const enviarSimulacao = async ({ dados }) => {
  try {
    const config = { headers: { "X-Gravitee-Api-Key": process.env.VUE_APP_SIMULACAO_API_KEY } };
    const { data } = await api.post(`${resource}/envia`, dados, config);
    return data;
  } catch (error) {
    const { response } = error;
    throw new AppError(response, 400);
  }
};

export default {
  enviarSimulacao,
  add,
  remover,
  getSimulacoes,
  getSimulacao,
};
