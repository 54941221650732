var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            dark: "",
            dense: "",
            color: _vm.$store.getters.getColor("primary"),
            fixed: "",
          },
        },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "espacamento-linhas pt-1" }, [
            _c("span", [_vm._v("Simulações")]),
          ]),
          _c("v-spacer"),
        ],
        1
      ),
      _vm.loading.page
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", color: "grey", size: "50" },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-container",
                { staticClass: "pa-5 pb-5 pt-0" },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "pb-5 pt-5 elevation-0",
                      attrs: { multiple: "", flat: "" },
                      model: {
                        value: _vm.panel,
                        callback: function ($$v) {
                          _vm.panel = $$v
                        },
                        expression: "panel",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { active: "" } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Nome")]),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.item.nome) + " "),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("E-mail")]),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.item.email) + " "),
                                ]),
                                _c("div", { staticClass: "col-12" }, [
                                  _c("strong", [_vm._v("Telefone")]),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formataTelefone(_vm.item.telefone)
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-row",
                                _vm._l(
                                  _vm.item.simulacoes,
                                  function (dado, index) {
                                    return _c(
                                      "v-col",
                                      {
                                        key: `item-${index}`,
                                        attrs: { cols: "12", md: "6", xl: "4" },
                                      },
                                      [
                                        _c("base-card-planos-simulacao", {
                                          staticClass: "cursor-pointer",
                                          attrs: {
                                            plano: dado.plano.nomePlanoAns,
                                            nascimento:
                                              dado.titular.dataNascimento,
                                            profissao: dado.titular.publicoAlvo,
                                            entidade: dado.plano.entidade,
                                            cidade: dado.endereco.cidade,
                                            valor: dado.plano.valorTotal,
                                            estado: dado.endereco.estado,
                                            dataCriacao: dado.dataCriacao,
                                            grupo: dado,
                                          },
                                          on: {
                                            planodetalhes: function ($event) {
                                              return _vm.verDetalhe(dado.guid)
                                            },
                                            selecionarsimulacao: function (
                                              $event
                                            ) {
                                              return _vm.selecionarSimulacoes(
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-10",
                          staticStyle: { bottom: "30px" },
                          attrs: {
                            color: "primary",
                            fixed: "",
                            dark: "",
                            right: "",
                            bottom: "",
                            fab: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialogEnvio = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-send")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-bottom-sheet",
                    {
                      attrs: { inset: "" },
                      model: {
                        value: _vm.dialogEnvio,
                        callback: function ($$v) {
                          _vm.dialogEnvio = $$v
                        },
                        expression: "dialogEnvio",
                      },
                    },
                    [
                      _c("v-sheet", [
                        _c(
                          "div",
                          { staticClass: "my-3" },
                          [
                            _c(
                              "v-list",
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.enviaSimulacao("whatsapp")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { tile: "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "green--text",
                                                attrs: { large: "" },
                                              },
                                              [_vm._v("mdi-whatsapp")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" WhatsApp "),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.enviaSimulacao("email")
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c(
                                          "v-avatar",
                                          { attrs: { tile: "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "primary--text",
                                                attrs: { large: "" },
                                              },
                                              [_vm._v("mdi-email")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(" Email "),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }