var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mb-2", staticStyle: { padding: "1rem" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex d-sm-none",
          staticStyle: { "justify-content": "center" },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-wrap",
              staticStyle: {
                height: "60px",
                width: "70px",
                "justify-content": "center",
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "13px",
                    "background-color": "red",
                    color: "white",
                    "text-align": "center",
                    height: "30%",
                    width: "100%",
                    padding: "3px 5px 0px 5px",
                  },
                },
                [_vm._v(_vm._s(_vm.mes) + "/" + _vm._s(_vm.ano))]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "text-align": "center",
                    height: "15%",
                    width: "100%",
                    color: "black",
                    "font-size": "30px",
                    "padding-bottom": "25px",
                  },
                },
                [_vm._v(_vm._s(_vm.dia))]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-checkbox",
        {
          attrs: { value: _vm.grupo },
          on: {
            click: function ($event) {
              return _vm.$emit("selecionarsimulacao", _vm.grupo.guid)
            },
          },
        },
        [
          _c("div", { attrs: { slot: "label" }, slot: "label" }, [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "d-none d-sm-flex flex-wrap",
                    staticStyle: { height: "60px" },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "13px",
                          "background-color": "red",
                          color: "white",
                          "text-align": "center",
                          height: "30%",
                          width: "100%",
                          padding: "0px 5px 0px 5px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.mes) + "/" + _vm._s(_vm.ano))]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "text-align": "center",
                          height: "15%",
                          width: "100%",
                          color: "black",
                          "font-size": "30px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.dia))]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-6",
                    staticStyle: { width: "100% !important" },
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("h3", { staticClass: "subtitle-1" }, [
                        _vm._v(_vm._s(_vm.plano)),
                      ]),
                    ]),
                    _c("div", { staticClass: "d-flex justify-space-between" }, [
                      _c("div", [
                        _c(
                          "h3",
                          { staticClass: "font-weight-bold mb-1 subtitle-2" },
                          [_vm._v(" Data Nascimento ")]
                        ),
                        _c("span", [
                          _vm._v(_vm._s(_vm.formataNascimento(_vm.nascimento))),
                        ]),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "ma-2",
                              attrs: { color: "primary", dark: "", label: "" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "mt-1 text-uppercase" },
                                [_vm._v(_vm._s(_vm._f("currency")(_vm.valor)))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", [
                        _c(
                          "h3",
                          { staticClass: "font-weight-bold mb-1 subtitle-2" },
                          [_vm._v("Profissão")]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.profissao))]),
                      ]),
                      _c("div", { staticClass: "ml-6" }, [
                        _c(
                          "h3",
                          { staticClass: "font-weight-bold mb-1 subtitle-2" },
                          [_vm._v("Entidade")]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.entidade))]),
                      ]),
                    ]),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", [
                        _c(
                          "h3",
                          { staticClass: "font-weight-bold mb-1 subtitle-2" },
                          [_vm._v("Cidade")]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.cidade))]),
                      ]),
                      _c("div", { staticClass: "ml-6" }, [
                        _c(
                          "h3",
                          { staticClass: "font-weight-bold mb-1 subtitle-2" },
                          [_vm._v("Estado")]
                        ),
                        _c("span", [_vm._v(_vm._s(_vm.estado))]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "v-col",
        { staticClass: "pa-0", attrs: { cols: "12" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-capitalize",
              attrs: { text: "", block: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("planodetalhes")
                },
              },
            },
            [
              _c("span", { staticClass: "mt-1 subtitle-2" }, [
                _vm._v("Clique para ver mais"),
              ]),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v("mdi-eye-outline"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }